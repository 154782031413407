<template>
  <div class="relative">
    <v-row
      justify="end"
      :class="{'primary': usp}"
      class="px-off-1"
      no-gutters
    >
      <template v-if="usp">
        <v-col v-for="(product, i) in products" :key="product.title" cols="12" sm="6" md="3" class="px-sm-3 mt-10 mb-4 ma-md-0">
          <v-card flat :color="$theme.isDefault ? colors[i] : 'white'" class="products d-flex flex-column fill-height px-6 pt-3">
            <v-card-title :class="{'primary--text text--lighten-2': !$theme.isDefault}" class="display-1 text-uppercase word-break">
              {{ product.title }}
            </v-card-title>
            <v-card-text class="d-flex flex-column grow primary--text pb-0">
              <span class="grow" v-html="product.text ? product.text : product.introduction"/>
              <br>
              <nuxt-link
                v-if="product.link"
                :to="product.link"
                :class="[{'text--lighten-6': $theme.is('textaid')}, {'pb-12': !product.demo_link}]"
                class="caption no-line primary--text"
              >
                Lees meer
              </nuxt-link>
            </v-card-text>
            <v-card-actions v-if="product.demo_link" class="products__action mb-n6 justify-end">
              <v-btn fab x-large :to="product.demo_link" nuxt :color="colors[i]" class="text-uppercase">
                Demo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
      <template v-else>
        <v-col v-for="(item, index) in news" :key="item.title" cols="12" sm="6" md="3" class="px-sm-2 my-2 ma-md-0">
          <v-card
            :to="{name: 'nieuws-category-item', params: {category: item.categories[0].slug, item: item.permalink}}"
            nuxt
            flat
            :color="colors[index]"
            height="100%"
            class="pa-4 pt-3"
          >
            <v-card-title class="display-1 word-break">
              {{ item.title }}
            </v-card-title>
            <v-card-text class="primary--text">
              {{ $moment(item.created_at).format('ll') }}
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="!usp" justify="end" class="px-off-1" no-gutters>
      <v-col class="shrink mt-6 mr-2">
        <v-btn rounded to="/nieuws" nuxt color="accent">
          Meer nieuws
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    usp: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
    news: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      colors: ['accent', 'primary lighten-4', 'primary lighten-6', 'accent darken-1'],
    };
  },
};
</script>

<style lang="sass" scoped>
.products
  transform: translateY(-8vh)
</style>
