import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      productSite: state => state.productSite.productSite,
    }),
  },
  head() {
    if (this.productSite) {
      return {
        title: this.productSite.meta_title,
        meta: [
          {
            hid: 'robots',
            name: 'robots',
            content: 'follow index',
          },
          this.productSite.meta_description || this.productSite.description
            ? {
              hid: 'description',
              name: 'description',
              content: this.productSite.meta_description || this.productSite.description,
            }
            : {},
          {
            hid: 'og:title',
            name: 'og:title',
            content: this.productSite.meta_title,
          },
          this.productSite.meta_description || this.productSite.description
            ? {
              hid: 'og:description',
              name: 'og:description',
              content: this.productSite.meta_description || this.productSite.description,
            }
            : {},
          this.productSite.image
            ? {
              hid: 'og:image',
              name: 'og:image',
              content: this.productSite.image,
            }
            : {},
        ],
      };
    }
  },
};
