import {mapState} from 'vuex';

export default {
  async fetch({store, route, error}) {
    try {
      let segments = route.path.replace(/^\//, '');

      if (route.fullPath.includes('nieuws/')) {
        segments = 'nieuws';
      }

      await store.dispatch('getPage', segments);
    } catch (e) {
      error({statusCode: 404, message: e.message});
    }
  },
  computed: mapState(['page']),
  head() {
    return {
      title: this.page.meta.title ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
        this.page.meta.index !== undefined
          ? {
            hid: 'robots',
            name: 'robots',
            content: (this.page.meta.follow ? 'follow ' : 'nofollow ') + (this.page.meta.index ? 'index' : 'noindex'),
          }
          : {},
        this.page.meta.title
          ? {
            hid: 'og:title',
            name: 'og:title',
            content: this.page.meta.title,
          } : {},
        this.page.meta.description
          ? {
            hid: 'og:description',
            name: 'og:description',
            content: this.page.meta.description,
          }
          : {},
        this.page.image
          ? {
            hid: 'og:image',
            name: 'og:image',
            content: this.page.image,
          }
          : {},
      ],
    };
  },
};
