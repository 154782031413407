<template>
  <section>
    <heading-row
      :image-url="productSite ? productSite.image : require('assets/img/index/woordhelder-home-1.jpg')"
      :title="productSite ? productSite.title : page.subtitel"
      :text="productSite ? productSite.text : page.tekst"
      :button="productSite ? '' : page.button_tekst"
      :button-link="productSite ? '' : page.button_link"
    />
    <card :usp="true" :products="productSite ? productSite.text_blocks : page.tekstblokken"/>
    <heading-row
      :reverse="true"
      :image-url="productSite ? productSite.image_2 : require('assets/img/index/woordhelder-home-2.jpg')"
      :title="productSite ? productSite.title_2 : page.subtitel_2"
      :text="productSite ? productSite.text_2 : page.tekst_2"
      :button="productSite ? '' : page.button_tekst_2"
      :button-link="productSite ? '' : page.button_link_2"
    />
    <card v-if="$theme.isDefault" class="news" :news="recentArticles"/>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import HeadingRow from '~/layouts/partials/HeadingRow';
import page from '~/plugins/mixins/page';
import productSite from '~/plugins/mixins/product-site';
import Card from '~/components/index/Card';

export default {
  components: {
    Card,
    HeadingRow,
  },
  mixins: [page, productSite],
  computed: {
    ...mapState({
      recentArticles: state => state.recentArticles,
    }),
  },
};
</script>

<style lang="sass" scoped>
.news
  margin: 0 0 86px 0
  @media screen and (min-width: map-get($grid-breakpoints, 'md'))
    margin: 112px 0 86px 0
</style>
